import {
    ORDER_ID,
    OrderTabs,
} from 'SourceComponent/MyAccountOrder/MyAccountOrder.config';

export {
    OrderTabs,
    ORDER_ID,
};

// TODO: implement ORDER_ACTION_LABELS
export const ORDER_ACTION_LABELS = {
    [OrderTabs.ORDER_INVOICES]: {
        print: __('Print Invoice'),
        printUrl: '/sales/order/printInvoice/invoice_id',
        printAll: __('Print Invoice'),
        printAllUrl: '/sales/order/printInvoice/order_id',
    },
    [OrderTabs.ORDER_SHIPMENTS]: {
        print: __('Print Shipment'),
        printUrl: '/sales/order/printShipment/shipment_id',
        printAll: __('Print Shipment'),
        printAllUrl: '/sales/order/printShipment/order_id',
    },
    [OrderTabs.ORDER_REFUNDS]: {
        print: __('Print Refund'),
        printUrl: '/sales/order/printCreditmemo/creditmemo_id',
        printAll: __('Print Refund'),
        printAllUrl: '/sales/order/printCreditmemo/order_id',
    },
    [OrderTabs.ORDER_ITEMS]: {},
};
