import {
    MyAccountQuery as SourceMyAccountQuery,
} from 'SourceQuery/MyAccount.query';

import { Field, Mutation, Query } from '@tilework/opus';

/** @namespace AlmusbahblendPwa/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    // TODO implement logic

    /**
     * Get ForgotPassword mutation
     * @param {{email: String}} options
     * @returns {Field}
     * @memberof MyAccount
     */
    getForgotPasswordMutation(
        options,
    ) {
        const { email } = options;

        return new Mutation('requestPasswordResetEmail')
            .addArgument('email', 'String!', email);

        // return new Mutation('forgotPassword')
        //     .addArgument('email', 'String!', email)
        //     .addField('status');
    }

    getTrackingData(hashCode) {
        console.log("received hashCode: ", hashCode);
        return new Field('getShippingTracking')
        .addArgument('hash', 'String', hashCode)
        .addFieldList(this._getTrackingResultFields());
    }

    _getTrackingResultFields() {
        return [
            new Field('shipment_id'),
            new Field('store_support_email'),
            this._getTrackingDetailsField(),
        ];
    }

    _getTrackingDetailsField() {
        return new Field('tracking_data')
            .addFieldList(this._getTrackingDetailsDataField());
    }

    _getTrackingDetailsDataField() {
        return [
            new Field('track_number'),
            new Field('carrier_title'),
            new Field('error_message'),
            new Field('summery'),
            new Field('track_url'),
            new Field('delivery_date'),
            new Field('progress_data')
            .addFieldList(this._getTrackingProgressDataFields()),
            new Field('delivery_info')
            .addFieldList(this._getTrackingDeliveryInfoFields()),
        ];
    }

    _getTrackingProgressDataFields() {
        return [
            new Field('delivery_date'),
            new Field('delivery_time'),
            new Field('delivery_location'),
            new Field('delivery_description'),
        ];
    }

    _getTrackingDeliveryInfoFields() {
        return [
            new Field('title'),
            new Field('value'),
        ];
    }
}

export default new MyAccountQuery();
