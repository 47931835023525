import {
    addProductToCart as sourceAddProductToCart,
    applyCouponToCart as sourceApplyCouponToCart,
    removeCouponFromCart as sourceRemoveCouponFromCart,
    removeProductFromCart as sourceRemoveProductFromCart,
    updateIsLoadingCart,
    updateShippingPrice,
    updateTotals,
} from 'SourceStore/Cart/Cart.action';

export {
    updateTotals,
    updateShippingPrice,
    updateIsLoadingCart,
};

// TODO: implement addProductToCart
export const addProductToCart = sourceAddProductToCart;

// TODO: implement removeProductFromCart
export const removeProductFromCart = sourceRemoveProductFromCart;

// TODO: implement applyCouponToCart
export const applyCouponToCart = sourceApplyCouponToCart;

// TODO: implement removeCouponFromCart
export const removeCouponFromCart = sourceRemoveCouponFromCart;
