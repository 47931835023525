import {
    CHECKOUT_URL_REGEX,
    CheckoutSteps as SourceCheckoutSteps,
    CheckoutUrlSteps,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY,
    UPDATE_SHIPPING_COST_ESTIMATES_FREQUENCY,
} from 'SourceRoute/Checkout/Checkout.config';

export {
    CheckoutUrlSteps,
    CHECKOUT_URL_REGEX,
    PAYMENT_TOTALS,
    UPDATE_EMAIL_CHECK_FREQUENCY,
    UPDATE_SHIPPING_COST_ESTIMATES_FREQUENCY,
};

// Because of onepagecheckout
export const CheckoutStepUrl = {
    CHECKOUT_URL: '/checkout',
    BILLING_URL: '/checkout/billing',
    SHIPPING_URL: '/checkout/shipping',
};

// ? TODO: Since override is to JavaScript, you need to handle overridden ENUMS
export const CheckoutSteps = { ...SourceCheckoutSteps, PAYMENT_STEP: 'PAYMENT_STEP', FAILURE_STEP: 'FAILURE_STEP' };