import {
    CartDispatcher as SourceCartDispatcher,
    CURRENT_WEBSITE as SOURCE_CURRENT_WEBSITE,
} from 'SourceStore/Cart/Cart.dispatcher';
import { NotificationType } from 'Store/Notification/Notification.type';
import { showNotification } from 'Store/Notification/Notification.action';
import agtm from './../../gtm/tags';
import { getCartId, setCartId } from 'Util/Cart';
import CartQuery from 'Query/Cart.query';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { getErrorMessage } from 'Util/Request/Error';
import { fetchMutation } from 'Util/Request/Mutation';
import { prepareQuery } from 'Util/Query';
import { executePost } from 'Util/Request/Request';
import ProductListQuery from 'Query/ProductList.query';
import { getIndexedProducts } from 'Util/Product';
import { executeGet } from 'Util/Request/Request';
import { fetchQuery } from 'Util/Request/Query';
// TODO: implement CURRENT_WEBSITE
export const CURRENT_WEBSITE = SOURCE_CURRENT_WEBSITE;

/** @namespace AlmusbahblendPwa/Store/Cart/Dispatcher */
export class CartDispatcher extends SourceCartDispatcher {
    // TODO implement logic
    async addProductToCart(
        dispatch,
        options,
    ) {
        // console.log("===dispatch in addtocart: ", dispatch);
        const { products = [], cartId: userCartId } = options;

        const cartId = userCartId || getCartId();

        if (!Array.isArray(products) || products.length === 0) {
            dispatch(showNotification(NotificationType.ERROR, __('No product data!')));

            return Promise.reject();
        }

        try {
            if (!cartId) {
                return await Promise.reject();
            }

            const { addProductsToCart: { user_errors: errors = [] } = {} } = await fetchMutation(
                CartQuery.getAddProductToCartMutation(cartId, products),
            );

            if (Array.isArray(errors) && errors.length > 0) {
                errors.forEach((error) => {
                    dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
                });

                return await Promise.resolve();
            }

            // Call GTM event here
            // console.log("Cart adding Product SKU: ", products);
            // this.gtmAddToCart(products);

            await this.updateInitialCartData(dispatch);
            dispatch(showNotification(NotificationType.SUCCESS, __('Product was added to cart!')));
        } catch (error) {
            if (!navigator.onLine) {
                dispatch(showNotification(NotificationType.ERROR, __('Not possible to fetch while offline')));

                return Promise.reject();
            }

            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));

            return Promise.reject();
        }

        return Promise.resolve();
    }

    async removeProductFromCart(dispatch, item_id) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const cartId = getCartId() || '';

            if (!isCustomerSignedIn && !cartId) {
                return null;
            }

            // find SKU by cart item_id
            const quoteId = await this._getCartId(dispatch);
            let cartItems = await fetchQuery(
                CartQuery.getCartQuery(
                    quoteId || '',
                ), 
            );

            if (cartItems.cartData.total_quantity <= 0) {
                throw new Error("The current cart is empty");
            }
            // console.log("cart Itemsss: ", cartItems.cartData.items);
            let removingItem = cartItems.cartData.items.find((element) => {
                return element.id === item_id
            });
            // console.log("cart removingItem: ", removingItem);

            
            const { removeItemFromCart: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getRemoveCartItemMutation(item_id, cartId),
            );
            // call GTM for remove cart Item
            // console.log("cart removingItem cartData: ", cartData);
            this.gtmRemoveItemFromCart(removingItem);
            this.gtmCartViewOrUpdate(cartData);

            this._updateCartData(cartData, dispatch);

            return cartData;
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error )));
            // window.location.reload();
            return null;
        }
    }

    async changeItemQty(dispatch, options) {
        const { uid, quantity = 1, cartId: originalCartId } = options;

        const cartId = !originalCartId ? getCartId() : originalCartId;
        // console.log("Change cart Item Qty options: ", options);
        try {
            if (!cartId) {
                return await Promise.reject();
            }

            // find SKU by cart item_id
            let cartItems = await fetchQuery(
                CartQuery.getCartQuery(
                    cartId || '',
                ), 
            );

            if (cartItems.cartData.total_quantity <= 0) {
                throw new Error("The current cart is empty");
            }
            // console.log("In change cart Itemsss: ", cartItems.cartData.items);
            let changingItem = cartItems.cartData.items.find((element) => {
                return element.uid === uid
            });
            // console.log("cart changeItemQty: ", changingItem);

            await fetchMutation(
                CartQuery.getUpdateCartItemsMutation({
                    cart_id: cartId,
                    cart_items: [
                        {
                            cart_item_uid: uid,
                            quantity,
                        },
                    ],
                }),
            );

            // Calling GTM for cart Qty change
            this.gtmChangeItemQtyInCart(changingItem, quantity);
            this.gtmCartViewOrUpdate(cartItems.cartData);
            
            return await this.updateInitialCartData(dispatch);
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
            // window.location.reload();
            return Promise.reject();
        }
    }

    async applyCouponToCart(dispatch, couponCode) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const cartId = getCartId() || '';

            if (!isCustomerSignedIn && !cartId) {
                throw new Error("The current cart is empty");
            }

            const { applyCouponToCart: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getApplyCouponMutation(couponCode, cartId),
            );

            // call GTM for coupon code apply
            this.gtmCouponApply(couponCode, cartData);
            this.gtmCartViewOrUpdate(cartData);

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification(NotificationType.SUCCESS, __('Coupon was applied!')));
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
            return Promise.reject();
        }
    }

    async applyGiftMessageToCart(dispatch, giftMessage) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const cartId = getCartId() || '';

            if (!cartId) {
                throw new Error("The current cart is empty");
            }

            await fetchMutation(
                CartQuery.getApplyGiftMessageMutation(cartId, giftMessage),
            );

            // this._updateCartData(cartData, dispatch);
            dispatch(showNotification(NotificationType.SUCCESS, __('Gift Message was added!')));
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
            return Promise.reject();
        }
    }

    async getGiftMessageFromCart(dispatch) {
        try {
            const cartId = getCartId() || '';

            if (!cartId) {
                throw new Error("The current cart is empty");
            }

            const data = await fetchQuery(
                CartQuery.getGiftMessageQuery(cartId)
            ).then((giftMessageData) => {
                return giftMessageData;
            });

            return data;

        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
        }
    }

    async removeGiftMessageFromCart(dispatch) {
        try {
            const cartId = getCartId() || '';

            if (!cartId) {
                throw new Error("The current cart is empty");
            }

            let cartItems = await fetchQuery(
                CartQuery.getCartQuery(
                    cartId || '',
                ), 
            );
            
            if (cartItems.cartData.total_quantity <= 0) {
                throw new Error("The current cart is empty");
            }

            const { removeGiftMessageFromCart } = await fetchMutation(
                CartQuery.getRemoveGiftMessageMutation(cartId),
            );

            // this._updateCartData(cartData, dispatch);
            dispatch(showNotification(NotificationType.SUCCESS, __('Gift Message was removed!')));
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
            window.location.reload();
            return;
        }
    }

    async removeCouponFromCart(dispatch) {
        try {
            const isCustomerSignedIn = isSignedIn();
            const cartId = getCartId() || '';

            if (!isCustomerSignedIn && !cartId) {
                throw new Error("The current cart is empty");
            }

            // let cartItems = await fetchQuery(
            //     CartQuery.getCartQuery(
            //         cartId || '',
            //     ), 
            // );
            
            // if (cartItems.cartData.total_quantity <= 0) {
            //     throw new Error("The current cart is empty");
            // }
            const { removeCouponFromCart: { cartData = {} } = {} } = await fetchMutation(
                CartQuery.getRemoveCouponMutation(cartId),
            );

            if(cartItems.cartData.coupon) {
                // call GTM for remove coupon
                this.gtmCouponRemove(cartItems.cartData.coupon, cartData);
                this.gtmCartViewOrUpdate(cartData);
            }
            

            this._updateCartData(cartData, dispatch);
            dispatch(showNotification(NotificationType.SUCCESS, __('Coupon was removed!')));
        } catch (error) {
            dispatch(showNotification(NotificationType.ERROR, getErrorMessage(error)));
            // window.location.reload();
            // return;
        }
    }

    gtmCouponApply(couponCode, cartData) {

        console.log("Cart Data apply coupon: ", cartData);
        const gtmObj = {
            coupon: couponCode,
            discount: cartData.prices.discount || 0.00,
            discountPercentage: 0.00,
            status: true,
        };

        agtm.couponEvent(gtmObj, 'apply', 'CartPage', 'buttonClick');
        
    }

    gtmCouponRemove(couponCode, cartData) {

        console.log("Cart Data remove coupon: ", cartData);
        const gtmObj = {
            coupon: couponCode,
            discount: cartData.prices.discount || 0.00,
            discountPercentage: 0.00,
            status: true,
        };
        agtm.couponEvent(gtmObj, 'remove', 'CartPage', 'buttonClick');
    }

    gtmCartViewOrUpdate(cartData) {
        const date_time = `${Math.floor(new Date() / 1000)}`
        let contentCategory = 'Update Cart'
        let contentName = 'Update Cart'
        let contentType = 'product_group'
        let contentIds = []
        let contents = []
        for (let i in cartData.items) {
            console.log(cartData.items[i])
            contentIds.push(cartData.items[i].sku)
            contents.push({ id: cartData.items[i].sku, quantity: cartData.items[i].quantity ? cartData.items[i].quantity : 1 })
        }
        agtm.cartViewOrUpdateEvent({
            products: cartData.items,
            summary: cartData.prices,
        }, 'updated', 'CartPage', 'en', { date_time, contentIds, contents, contentType, contentName, contentCategory });
    }

    async gtmAddToCart(products) {
        
        let sku = products[0].sku;
        let quantity = products[0].quantity;
        const cacheLifetime = 86400;

        const options = {
            args: {
                filter: {
                    productSKU:sku,
                },
            },
            isSingleProduct:true,
        };

        const query = [ProductListQuery.getQuery(options)];
        console.log("Cart adding Product Data: ", products);
        executeGet(prepareQuery(query), 'AddingProduct', cacheLifetime)
            .then(
                /** @namespace Component/NewProducts/Container/NewProductsContainer/requestProducts/then/catch/executeGet/then */
                ({ products: { items } }) => {
                     let productData = getIndexedProducts(items);
                     console.log("Cart adding Product productData1: ", productData);
                    if (productData.length > 0) {
                        console.log("Cart adding Product productData2: ", productData[0]);
                        const addingProduct = productData[0];
                        var date_time = `${Math.floor(new Date() / 1000)}`;          
                        let contentCategory = 'Adding To Cart'
                        let contentType = 'product'
                        let contentName = addingProduct.name
                        let contentIds = [addingProduct.sku]
                        let contents = [{id:addingProduct.sku , quantity:products[0].quantity}]

                        agtm.cartPageEvent(
                        date_time,
                        addingProduct,
                        "add",
                        "ProductPage",
                        "AddToCartClick",
                        'en',
                        {date_time ,contentIds ,contents , contentName , contentType , contentCategory}
                        );
                    }
                }
            )
            .catch(
                /** @namespace Component/NewProducts/Container/NewProductsContainer/requestProducts/then/catch/showNotification */
                (e) => { 
                    showNotification(NotificationType.ERROR, __('Error fetching Required Product!'), e);
                }
            );
    
    }

    async gtmRemoveItemFromCart(item) {
        
        let sku = item.sku;
        let quantity = item.quantity;
        const cacheLifetime = 86400;

        const options = {
            args: {
                filter: {
                    productSKU:sku,
                },
            },
            isSingleProduct:true,
        };

        const query = [ProductListQuery.getQuery(options)];

        executeGet(prepareQuery(query), 'RemovingProduct', cacheLifetime)
            .then(
                /** @namespace Component/NewProducts/Container/NewProductsContainer/requestProducts/then/catch/executeGet/then */
                ({ products: { items } }) => {
                     let productData = getIndexedProducts(items);
                    //  console.log("Cart removing Product Data: ", productData);
                    if (productData.length > 0) {
                        const removingProduct = productData[0];
                        var date_time = `${Math.floor(new Date() / 1000)}`;          
                       
                        agtm.cartPageEvent(
                        date_time,
                        removingProduct,
                        "remove",
                        "CartPage",
                        "buttonClick",
                        'en',
                        quantity
                        );
                    }
                }
            )
            .catch(
                /** @namespace Component/NewProducts/Container/NewProductsContainer/requestProducts/then/catch/showNotification */
                (e) => { 
                    showNotification(NotificationType.ERROR, __('Error fetching Required Product!'), e);
                }
            );
    
    }

    async gtmChangeItemQtyInCart(item, newqty) {
        
        let sku = item.sku;
        let oldQuantity = item.quantity;
        const cacheLifetime = 86400;

        const options = {
            args: {
                filter: {
                    productSKU:sku,
                },
            },
            isSingleProduct:true,
        };

        const query = [ProductListQuery.getQuery(options)];

        executeGet(prepareQuery(query), 'ChangingProduct', cacheLifetime)
            .then(
                /** @namespace Component/NewProducts/Container/NewProductsContainer/requestProducts/then/catch/executeGet/then */
                ({ products: { items } }) => {
                     let productData = getIndexedProducts(items);
                    //  console.log("Cart removing Product Data: ", productData);
                    if (productData.length > 0) {
                        let changingProduct = productData[0];
                        changingProduct['old_cart_qty']= oldQuantity;
                        changingProduct['new_cart_qty']= newqty;       
                        const diff = +newqty - +oldQuantity;
                        var date_time = `${Math.floor(new Date() / 1000)}`;   
                        console.log("final cart changingProduct: ", changingProduct);
                        agtm.cartPageEvent(
                            date_time,
                            changingProduct,
                            (diff > 0) ? 'increment' : 'decrement',
                            "CartPage",
                            "quantityChange",
                            'en',
                            (diff < 0) ? -(diff) : diff,
                        );
                    }
                }
            )
            .catch(
                /** @namespace Component/NewProducts/Container/NewProductsContainer/requestProducts/then/catch/showNotification */
                (e) => { 
                    showNotification(NotificationType.ERROR, __('Error fetching Required Product!'), e);
                }
            );
    
    }
}

export default new CartDispatcher();
